import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";
import axios from "axios";

export const LOCATION_API = {
  getArea: async () => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/location/area`)
  ),
  getCities: async () => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/location/cities/list`)
  ),
  getCountry: async () => {
    const response = await axios.get('https://api.cloudflare.com/cdn-cgi/trace');
    const data = response.data.split('\n').reduce((acc, line) => {
      const [key, value] = line.split('=');
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return data.loc;
  },
};