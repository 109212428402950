export const IMAGES_CONSTANTS = {
  INITIAL: '0ba1dffc-9088-4c94-a19d-3160c313f900',
  HOME_BANNER_CAROUSEL: {
    PROPERTY: 'fa10c4eb-fc9b-425d-d5a0-176965b56700',
    CAR: '3aa26a12-582c-49c4-c583-1d3f6e12f700',
    // 0db6a6fd-ca28-40ec-22ed-7f2d89437600
    TOUR: '1605c99a-b3be-4cf0-08be-acaf18cf8000',
  },
  NATIVE_APP_PROMO: {
    APPLE_STORE: 'fe96977e-5807-4214-e674-2ee01dc58f00',
    GOOGLE_PLAY: '0662338a-105b-4d72-2259-cad5f3fc9400',
    TEL_LEFT: '3ce61297-731f-42ef-d3ed-1f873a91c700',
    TEL_RIGHT: 'fa9ada44-1fb7-4ae7-8226-4c73544a5a00',
  },
  ABOUT_US_SERVICE: {
    PROPERTY: 'aab5c97d-54b8-4688-55af-e3cb2d746500',
    CAR: '6a8c9dbe-2167-487e-8dd4-44084d505900',
    TOUR: '5fe32180-a4d4-42af-fb4b-72eee08cc200',
    DESIGNER: '2f1deb69-a1c2-42db-9e09-5961d0db4800',
  },
};
