import {useLocation, useNavigate} from "react-router-dom";
import {Fragment, useEffect, useMemo, useState} from "react";
import {MobileNavItem} from "./MobileNavItem/MobileNavItem";
import {useSelector} from "react-redux";
import {SVG_ICON} from "../common/svg/svg-icon";
import {CONSTANTS} from "../../constants/constants";
import {mobileNavConfig} from "./mobileNavConfig";
import styles from './MobileNav.module.css';

export const MobileNav = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;

  const {isAuth} = useSelector(state => state.user);
  const imageProfile = useSelector(state => state.user.dataPeople?.image);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLongVisible, setIsLongVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLongVisible(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  const accountTab = useMemo(() => ({
    title: `account`,
    link: CONSTANTS?.ROUTES?.PROFILE?.link(),
    path: CONSTANTS?.ROUTES?.PROFILE?.path,
    image: imageProfile || null,
  }), [imageProfile]);

  const authTab = useMemo(() => ({
    title: `singIn`,
    link: CONSTANTS?.ROUTES?.AUTH?.link(),
    path: CONSTANTS?.ROUTES?.AUTH?.path,
    icon: SVG_ICON.LOGIN,
  }), []);

  const tabs = useMemo(() => ([
    ...mobileNavConfig,
    isAuth ? accountTab : authTab,
  ]), [isAuth, accountTab, authTab]);

  return (
    <nav
      className={styles.wrapper}
      style={{gridTemplateColumns: `repeat(${tabs.length}, 1fr)`}}
    >
      {pathname !== CONSTANTS?.ROUTES?.HOME?.path && (
        <div
          className={`${styles.long}`}
          style={{
            left: 0,
            transform: `translateX(${selectedIndex * 100}%) scale(${isLongVisible ? '1, 1' : '0, 1'})`,
            width: `${100 / tabs.length}%`
          }}
        ></div>
      )}
      {tabs?.map((item, index) => {
        return (
          <Fragment key={item?.title}>
            <MobileNavItem
              data={item}
              isActive={pathname === item?.path}
              setItem={() => navigate(item?.link)}
              setSelectedIndex={() => setSelectedIndex(index)}
            />
          </Fragment>
        )
      })}
    </nav>
  )
}