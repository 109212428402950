import {useState, useRef, useCallback, useLayoutEffect, useMemo} from "react";
import useResponsiveImage from "./useResponsiveImage";
import {useInView} from "react-intersection-observer";
import {MainLoading} from "../common/loaders/MainLoading/MainLoading";
import {CONSTANTS} from "../../constants/constants";
import styles from './ImageContainer.module.css';

export const ImageContainer = ({
  id,
  aspectRatio = '1/1',
  option,
  scaleFactor = 1,
  isBlur = true,
  loader = { enable: false, option },
  isLoadingFon = true,
  style = {},
  isForceVisible = false,
  shouldRenderBlur = true,
}) => {

  const boxRef = useRef(null);
  const [ref, inView] = useInView({threshold: 0.1, triggerOnce: true});

  const isElementVisible = useMemo(() => isForceVisible || inView, [isForceVisible, inView]);

  const {main, blur, error} = useResponsiveImage(id, boxRef, CONSTANTS?.IMAGES?.INITIAL, option, scaleFactor);

  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBlurLoaded, setIsBlurLoaded] = useState(!isBlur);
  const [isBlurError, setIsBlurError] = useState(false);

  useLayoutEffect(() => {
    if (id) {
      setIsLoaded(false);
      setIsBlurLoaded(!isBlur || !shouldRenderBlur);
      setIsError(false);
      setIsBlurError(false);
    }
  }, [id, isBlur, shouldRenderBlur]);

  useLayoutEffect(() => {
    if (id && isElementVisible && isBlurLoaded && !isLoaded && !isBlurError && main) {
      const img = new Image();
      img.src = main;
      img.onload = () => setIsLoaded(true);
      img.onerror = () => setIsError(true);
    }
  }, [id, isElementVisible, main, isBlurLoaded, isLoaded, isBlurError]);

  const setRefs = useCallback(node => {
    boxRef.current = node;
    ref(node);
  }, [ref]);

  return (
    <div
      className={`${styles.box} ${isLoadingFon && !isBlurLoaded ? styles['box-active-back'] : ''}`}
      style={{aspectRatio, ...style}}
      ref={setRefs}
    >
      {isLoadingFon && !isLoaded && id && !isBlurLoaded && (
        <div className="loading-fon"></div>
      )}

      {loader?.enable && !isLoaded && id && (
        <div className={styles['loader-block']}>
          <MainLoading {...loader?.option}/>
        </div>
      )}

      {shouldRenderBlur && isBlur && !isBlurError && blur && (
        <img
          decoding="async"
          className={styles.imgLoading}
          alt={`loading-${blur}`}
          src={blur}
          onLoad={() => setIsBlurLoaded(true)}
          onError={() => setIsBlurError(true)}
        />
      )}

      {id && isElementVisible && !isError && !isBlurError && isBlurLoaded && main && (
        <img
          decoding="async"
          className={styles.img}
          src={main}
          alt={`${main}`}
        />
      )}

      {((id && isElementVisible && isError) || (isBlurError && id)) && (
        <img
          decoding="async"
          className={styles.img}
          src={error}
          alt={`${error}`}
        />
      )}
    </div>
  );
};
