import { createSlice } from '@reduxjs/toolkit';
// import moment from "moment";

const initialState = {
  property: {
    areaId: null,
    checkIn: null, //moment().startOf('day').format(),
    checkOut: null, //moment().add(1, 'days').startOf('day').format(),
    adults: null,
    children: null,
    infants: null,
    bedRooms: null,
  },
  car: {
    categoryId: [],
    cityId: null,
    pickUp: null, //moment().startOf('day').format(),
    dropOff: null, //moment().add(1, 'days').startOf('day').format(),
  },
  tour: {
    categoryId: [],
    cityId: null,
    adults: null,
    children: null,
    infants: null,
  },
};

const homeOptionsSlice = createSlice({
  name: 'homeOptionsSlice',
  initialState,
  reducers: {
    setPropertyOptions: (state, action) => {
      const { key, value } = action.payload;
      state.property[key] = value;
    },
    setCarOptions: (state, action) => {
      const { key, value } = action.payload;
      state.car[key] = value;
    },
    setTourOptions: (state, action) => {
      const { key, value } = action.payload;
      state.tour[key] = value;
    },
  }
});

export const {
  setPropertyOptions,
  setCarOptions,
  setTourOptions,
} = homeOptionsSlice.actions;

export default homeOptionsSlice.reducer;