import { createSlice } from '@reduxjs/toolkit';
import {localStorageService} from "../../services/localStorageService";
import {CONSTANTS} from "../../constants/constants";

const initialState = {
  isAuth: !!localStorageService.get(CONSTANTS?.VALUE?.ACCESS_TOKEN),
  country: 'BY',
  currency: localStorageService.get(CONSTANTS?.VALUE?.CURRENCY),
  language: localStorageService.get(CONSTANTS?.VALUE?.LANGUAGES),
  dataPeople: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuth (state, action)  {
      state.isAuth = action.payload;
    },
    setCurrency (state, action) {
      const { currency } = action.payload;
      state.currency = currency;
    },
    setLanguage (state, action) {
      const { language } = action.payload;
      state.language = language;
    },
    setDataPeople (state, action) {
      const { dataPeople } = action.payload;
      state.dataPeople = dataPeople;
    },
    resetUser (state) {
      state.dataPeople = initialState.dataPeople;
    },
  }
});

export const {
  setIsAuth,
  setCurrency,
  setLanguage,
  setDataPeople,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;