import { useEffect, useMemo, useState } from "react";
import styles from './MainLoading.module.css';

export const MainLoading = ({time = 2, quantity = 6, between = 8, size = 10, style, margin }) => {

  const [newTime, setNewTime] = useState(time * 0.75);

  useEffect(() => {
    setTimeout(() => setNewTime(time), (time * 1000 * 4) / 8);
  }, [time]);

  const long = useMemo(() => size * quantity * 0.3 + (quantity - 1) * between, [between, quantity, size]);

  const keyframesStyle = `
    @keyframes move {
     0% { transform: translateX(${0}px) scale(0.6); opacity: 1}
     12.5% { transform: translateX(${(long - size) / 4}px) scale(0.8); opacity: 1}
     25% { transform: translateX(${(long - size) * 2/4}px) scale(1); opacity: 1}
     37.5% { transform: translateX(${(long - size) * 3/4}px) scale(0.8); opacity: 1}
     50% { transform: translateX(${(long - size) * 4/4}px) scale(0.6); opacity: 1}
     62.5% { transform: translateX(${(long - size) * 3/4}px) scale(0.4); opacity: 0.2}
     75% { transform: translateX(${(long - size) * 2/4}px) scale(0.2); opacity: 0}
     87.5% { transform: translateX(${(long - size) / 4}px) scale(0.4); opacity: 0.2}
     100% { transform: translateX(${0}px) scale(0.6); opacity: 1}
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style>
      <div
        className={styles.container}
        style={{
          width: `${long}px`,
          height: `${size}px`,
          maxWidth: `${long}px`,
          margin
        }}
      >
        {[...Array(quantity)].map((_, index) => (
          <div
            key={index}
            className={styles.item}
            style={{
              width: `${size}px`,
              height: `${size}px`,
              animationName: 'move',
              animationDuration: `${newTime}s`,
              animationTimingFunction: 'ease-in-out',
              animationIterationCount: 'infinite',
              animationDelay: `${index * (newTime / quantity)}s`,
              ...style,
            }}
          ></div>
        ))}
      </div>
    </>
  );
};