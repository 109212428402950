import {CONSTANTS} from "../../constants/constants";
import {SVG_ICON} from "../common/svg/svg-icon";

export const mobileNavConfig = [
  {
    title: `rental_types.property`,
    link: CONSTANTS?.ROUTES?.PROPERTY?.link(),
    path: CONSTANTS?.ROUTES?.PROPERTY?.path,
    icon: SVG_ICON.NAV_STAY,
  },
  {
    title: `rental_types.car`,
    link: CONSTANTS?.ROUTES?.CAR?.link(),
    path: CONSTANTS?.ROUTES?.CAR?.path,
    icon: SVG_ICON.NAV_CAR,
  },
  {
    title: `rental_types.tour`,
    link: CONSTANTS?.ROUTES?.TOUR?.link(),
    path: CONSTANTS?.ROUTES?.TOUR?.path,
    icon: SVG_ICON.NAV_TOUR
  },
];