import styles from './LoadingScreen.module.css';
import {useDelayedVisibility} from "../../hooks/useDelayedVisibility";

export const LoadingScreen = () => {

  const visibility = useDelayedVisibility(150);

  return (
    <div className={styles.container}>
      <span className={styles.logo}>
        {visibility && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 512 512"
            width="100%"
            height="100%"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <path
                d="M 108.37 174.10 C 115.25 173.90 122.14 174.02 129.01 174.02 C 143.67 174.41 158.39 173.78 173.04 174.40 C 182.05 175.66 191.98 176.86 200.31 180.58 C 206.33 183.03 211.75 187.11 216.25 191.77 C 223.60 199.73 226.84 210.29 226.62 221.01 C 226.74 230.47 222.84 239.51 216.07 246.06 C 206.03 256.42 191.57 260.49 177.71 262.51 C 192.48 265.23 205.78 273.52 213.56 286.52 C 220.64 298.92 226.48 312.05 233.66 324.40 C 238.15 331.56 244.84 334.01 252.10 337.39 C 237.72 337.49 223.33 337.27 208.95 337.36 C 202.12 323.95 195.73 310.32 188.95 296.89 C 186.01 291.47 183.79 285.81 180.35 280.65 C 176.32 274.73 173.12 268.93 166.40 265.68 C 160.66 262.63 155.23 263.43 149.00 263.36 C 148.86 281.24 148.85 299.12 148.85 317.00 C 148.94 321.34 148.26 325.37 150.83 329.17 C 153.24 333.12 156.93 334.48 160.47 337.17 C 142.91 337.15 125.32 337.13 107.76 337.07 C 111.11 334.72 114.48 333.32 117.02 329.97 C 119.51 327.10 119.68 323.67 119.77 320.05 C 119.72 278.06 120.01 235.99 120.01 194.00 C 119.92 189.68 120.58 185.78 118.03 182.00 C 115.64 178.10 111.92 176.73 108.37 174.10 Z M 149.26 176.84 C 148.96 193.23 149.20 209.62 149.11 226.01 C 148.90 237.51 149.04 249.01 149.01 260.52 C 160.40 260.45 172.45 261.80 182.74 255.72 C 191.13 250.59 193.83 241.14 195.04 231.96 C 196.27 220.36 196.08 207.72 192.38 196.56 C 189.91 188.98 184.07 182.23 176.48 179.51 C 168.05 176.16 158.21 177.01 149.26 176.84 Z"
                id="logo_1"/>
              <path
                d="M 238.72 174.14 C 244.80 173.86 250.91 174.05 257.00 174.00 C 270.67 174.29 284.34 174.05 298.01 174.18 C 295.41 176.75 291.51 179.17 291.02 183.03 C 290.20 186.47 292.29 189.36 293.59 192.39 C 309.25 226.99 324.93 261.61 340.56 296.22 C 353.91 262.01 367.22 227.79 380.56 193.57 C 381.92 189.83 384.27 186.25 382.71 182.18 C 381.92 178.74 377.78 176.67 375.27 174.45 C 384.81 174.46 394.39 174.18 403.92 174.63 C 398.54 177.61 393.32 179.34 388.92 183.92 C 386.57 186.32 385.52 189.17 384.31 192.22 C 365.79 240.15 346.84 288.04 328.42 336.00 C 327.39 336.06 326.36 336.11 325.33 336.17 C 303.10 287.54 281.06 238.77 258.88 190.12 C 257.55 187.28 255.80 184.69 253.40 182.65 C 249.12 178.87 243.58 177.47 238.72 174.14 Z"
                id="logo_2"/>
            </defs>
            <g>
              <use className={`${styles.strokeAnimation} ${styles.logo1}`} xlinkHref="#logo_1"/>
              <use className={`${styles.strokeAnimation} ${styles.logo2}`} xlinkHref="#logo_2"/>
            </g>
          </svg>
        )}
      </span>
    </div>
  );
}