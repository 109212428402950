import {createAsyncThunk} from "@reduxjs/toolkit";
import {setCurrency, setDataPeople, setLanguage} from "../slices/userSlice";
import {API} from "../../services/api/api";
import {CONSTANTS} from "../../constants/constants";
import {localStorageService} from "../../services/localStorageService";
import {getCurrencyByCountry, getLanguageByCountry} from "../../utils/localizationUtils";
import i18n from "i18next";

export const getUserThunk = createAsyncThunk(
  'user/getUser',
  async (_, { dispatch }) => {
    try {
      const res = await API.USER.getUser();

      if (res?.data?.statusCode === CONSTANTS?.API?.STATUS_CODE?.SUCCESS) {
        dispatch(setDataPeople({ dataPeople: res?.data?.data }));
      }
    } catch (error) {
      throw Error(error);
    }
  }
);

export const initializeCurrencyThunk = createAsyncThunk(
  'user/getCurrency',
  async (_,{ dispatch }) => {
    try {
      const country = await API.LOCATION.getCountry();

      if (!!country) {
        const currency = getCurrencyByCountry(
          country,
          CONSTANTS?.CONFIG?.CURRENCY?.AVAILABLE,
          CONSTANTS?.CONFIG?.CURRENCY?.DEFAULT,
        );
        dispatch(setCurrencyThunk({currency}));
      }
    } catch (error) {
      dispatch(setCurrencyThunk({currency: CONSTANTS?.CONFIG?.CURRENCY?.DEFAULT}));
      throw Error(error);
    }
  }
);

export const setCurrencyThunk = createAsyncThunk(
  'auth/setCurrency',
  async ({currency}, { dispatch }) => {
    try {
      dispatch(setCurrency({currency}));
      localStorageService.set(CONSTANTS?.VALUE?.CURRENCY, currency);
    } catch (error) {
      throw Error(error);
    }
  }
);

export const initializeLanguageThunk = createAsyncThunk(
  'user/getLanguage',
  async (_,{ dispatch }) => {
    try {
      const country = await API.LOCATION.getCountry();

      if (!!country) {
        const language = getLanguageByCountry(
          country,
          CONSTANTS?.CONFIG?.LANGUAGES?.AVAILABLE,
          CONSTANTS?.CONFIG?.LANGUAGES?.DEFAULT,
        );
        dispatch(setLanguageThunk({language}));
      }
    } catch (error) {
      dispatch(setLanguageThunk({language: CONSTANTS?.CONFIG?.LANGUAGES?.DEFAULT}));
      throw Error(error);
    }
  }
);

export const setLanguageThunk = createAsyncThunk(
  'auth/setCurrency',
  async ({language}, { dispatch }) => {
    try {
      await i18n.changeLanguage(language.VALUE);
      document.documentElement.lang = i18n.language;
      dispatch(setLanguage({language}));
      localStorageService.set(CONSTANTS?.VALUE?.LANGUAGES, language);
    } catch (error) {
      throw Error(error);
    }
  }
);